




















































import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PenCanvasMath from '@/components/modules/drills/atoms/PenCanvasMath.vue'
import TabsButtonMath from '@/components/modules/drillsv3/molecules/TabsButtonMath.vue'
import ModalChatMath from '@/components/modules/drillsv3/organisms/ModalChatMath.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/v3/ModalBase.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'
import DrillPenAi from '@/components/organisms/study/DrillPenAi.vue'
import DrillPenMath from '@/components/organisms/study/DrillPenMath.vue'
import { LINE_WIDTH, PEN_COLOR, PEN_COLOR_KEY } from '@/constants'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import CanvasMethod from '@/mixins/v3/canvas'
import { DRILL_TYPE } from '@/store/modules/DrillsV3'
import { CanvasDataTypeMemo } from '@/types/canvas'
import { QuestionsMemoMathType } from '@/types/memo'
import { Debounce } from 'vue-debounce-decorator'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
const HEIGHT_TAB_BUTTON = 72
@Component({
  components: {
    ModalBase,
    DrillPen,
    PenCanvasMath,
    DrillPenAi,
    TabsButtonMath,
    ButtonBase,
    DrillPenMath,
    ModalChatMath
  },
})
export default class ModalMemoMath extends Mixins(ModalBaseMethod, CanvasMethod, DrillWebApi) {
  @Ref() tabButtonRef!: TabsButtonMath

  @Ref() memoContentRef!: HTMLElement

  @Ref() contentRef!: HTMLElement

  @Ref() answerRef!: HTMLElement

  @Ref() buttonTabRef!: HTMLElement

  @Ref() modalChatRef!: ModalChatMath

  @Ref() drillPenMathRef!: DrillPenMath

  @Prop({ default: [] })
  questions?: QuestionsMemoMathType[]

  @Prop({ default: '' })
  title?: string

  @Prop({
    default: {
      pen: false,
      button: false,
      buttonHint: false,
      buttonRetry: true
    }
  })
  hiddenUi?: {
    pen: boolean
    button: boolean
    buttonHint: boolean
    buttonRetry: boolean
  }

  @Prop({
    default: {}
  })
  params?: {
    curriculumSUnitId: number,
    resultDrillId: number
  }

  @Prop({ default: false })
  isShowAnswer?: boolean

  @Prop()
  onSaveMemoSuccess?: (questionCode: string) => void


  @Prop()
  onChangeTabButton!: (questionCode: string, other?: { isRefetch?: boolean }) => Promise<any>

  @Prop({ default: false })
  isTeacher?: boolean

  private canvasMemoStudent = 'canvasMemoStudent'
  private statusBookmark = false
  private arrHiddenColor = [PEN_COLOR_KEY.BLACK, PEN_COLOR_KEY.GREEN, PEN_COLOR_KEY.ORANGE]
  private currentQuestion: QuestionsMemoMathType = {
    memoUrl: '',
    page: 1,
    questionCode: '',
    questionUrls: [],
    answerUrl: '',
    memoUrlTeacher: '',
  }
  private tabButtonData: { id: string; text: string }[] = []
  private imageWidth = 0
  private imagePreviousWidth = 0
  private rationOfQuestion = 0
  private currentCanvasData: CanvasDataTypeMemo = {}

  private calculatorHeightMemo() {
    const isHaveAnswer = this.isShowAnswer
    const memoEl = this.memoContentRef
    const buttonTabEl = this.buttonTabRef
    memoEl.style.height = '0px'
    const answerEl = this.answerRef

    if (isHaveAnswer) {
      answerEl.style.height = '0px'
    }

    const contentElHeight = this.contentRef.clientHeight

    const endHeight = (contentElHeight - HEIGHT_TAB_BUTTON) / (isHaveAnswer ? 2 : 1)

    memoEl.style.height = endHeight + 'px'

    if (isHaveAnswer) {
      buttonTabEl.style.order = '0'
      answerEl.style.height = endHeight + 'px'
    } else {
      buttonTabEl.style.order = '-1'
    }
  }

  private handleClickTab(data: { id: string }) {
    const questionCode = data.id.split('_')[0]

    this.currentCanvasData[this.currentQuestion.questionCode] = {
      memo: {
        ...this.getDataStroke(),
      }
    }

    this.tabButtonRef.setCurrentTab(data.id)
    this.onChangeTabButton?.(questionCode)
    this.setDataStroke(this.currentCanvasData[this.currentQuestion.questionCode].memo)
  }

  private handleLoadMemo(base64Url: string, base64UrlTeacher?: string) {
    this.clearCanvas()
    this.getCurrentCanvas(this.canvasMemoStudent).clearCanvas()
    this.drawImageFromMemoStudentUrl(base64Url)
    //need draw stroke first then draw image
    this.drawCanvasFromStroke()
    this.drawImageFromMemoTeacherUrl(base64UrlTeacher)
  }

  private drawImageFromMemoStudentUrl(memoUrl: string) {
    if (!memoUrl) return;

    const image = new Image()
    image.src = memoUrl
    const _this = this

    try {
      Vue.prototype.$loading.start()
      image.onload = () => {
        const ratio = image.width / image.height
        _this.getCurrentCanvas(_this.canvasMemoStudent).context.drawImage(image, 0, 0, this.imageWidth, this.imageWidth / ratio)
      }
    } catch (err) {
      console.log(err)
    } finally {
      Vue.prototype.$loading.complete()
    }
  }

  private drawImageFromMemoTeacherUrl(memoUrlTeacher?: string) {
    if (!memoUrlTeacher) return;

    const _this = this

    try {
      Vue.prototype.$loading.start()

      //image teacher
      let imageTeacher: any;

      if (memoUrlTeacher && this.isTeacher || !this.hiddenUi?.pen) {
        imageTeacher = new Image()
        imageTeacher.src = memoUrlTeacher
        imageTeacher.onload = () => {
          const ratio = imageTeacher.width / imageTeacher.height
          const context = _this.getCurrentCanvas().context
          const compositeOperation = _this.getCompositeOperation();
          const isEraseMode = compositeOperation === 'destination-out'
          if (isEraseMode && context) {
            context.globalCompositeOperation = 'source-over'
          }
          // context.globalCompositeOperation = 'destination-over'
          context.drawImage(imageTeacher, 0, 0, this.imageWidth, this.imageWidth / ratio)
          // context.globalCompositeOperation = 'source-over'
          if (isEraseMode && context) {
            context.globalCompositeOperation = 'destination-out'
          }
        }
      }

    } catch (err) {
      console.log(err)
    } finally {
      Vue.prototype.$loading.complete()
    }
  }


  private drawCanvasFromStroke() {
    const scaleX = this.imageWidth / (this.imagePreviousWidth || this.imageWidth)
    const scaleY = (this.imageWidth / this.rationOfQuestion) / ((this.imagePreviousWidth || this.imageWidth) / this.rationOfQuestion)

    const dataCanvas = this.currentCanvasData[this.currentQuestion.questionCode]?.memo


    if (dataCanvas) {
      const newStrokes = dataCanvas.undoStack.map((stroke: any) =>
        stroke.map((point: any) => ({
          ...point,
          x: point.x * scaleX,
          y: point.y * scaleY,
        }))
      )

      if (newStrokes.length) {
        this.redraw(newStrokes)
      }
    }
  }

  public setCurrentQuestion(data: QuestionsMemoMathType) {
    this.currentQuestion = data
  }

  //modal
  public showModal() {
    this.show()
    const _this = this
    this.handleGetStatusBookmark()

    setTimeout(() => {
      _this.handleResize()
      _this.tabButtonRef.setCurrentTab(_this.currentQuestion.questionCode + '_' + _this.currentQuestion.page)
      if (!_this.hiddenUi?.pen) {
        _this.changePenColor(PEN_COLOR.RED);
        _this.drillPenMathRef.onSetActiveColor(PEN_COLOR_KEY.RED)
        _this.activateCanvas()
        _this.changeLineWidth(LINE_WIDTH.MEDIUM)
      }

    })
  }

  private handleOpened() {
    document.body.style.overflow = 'hidden'
    window.addEventListener('resize', this.handleResize)
  }

  private beforeClose() {
    document.body.style.overflow = 'unset'
    window.removeEventListener('resize', this.handleResize)
  }


  private async handleChangeStatusBookmark() {
    const currentStatus = this.statusBookmark
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_bookmarks`, {
      question_code: this.currentQuestion.questionCode,
      curriculum_s_unit_id: this.params?.curriculumSUnitId,
      result_drill_id: this.params?.resultDrillId,
    })
    Vue.prototype.$loading.complete()
    this.statusBookmark = !this.statusBookmark
    if (!currentStatus) {
      alert('問題をブックマークを保存しました。')
    } else {
      alert('問題をブックマークから削除しました。')
    }
  }

  private handleChangePen(color: string) {
    if (color.length) {
      this.activateCanvas()
      this.changePenType({ colorCode: color, alpha: 1 })
    } else {
      this.deactivateCanvas()
    }
  }

  private async handleDoPen(type: 'undo' | 'redo' | 'delete') {
    if (type === 'undo') {
      this.undo()
    } else if (type === 'redo') {
      this.redo()
    } else this.deleteCanvas()
    this.drawImageFromMemoTeacherUrl(this.currentQuestion.memoUrlTeacher)
  }

  protected async submitSimilar() {
    if (this.isTeacher) {
      this.handleSimilarOrRetryHint('challenge_similar')
      return;
    }
    try {
      Vue.prototype.$loading.start()
      const startRetry = await Vue.prototype.$http.httpWithToken.post('v3/similar_questions/drills/start', {
        question_code: this.currentQuestion.questionCode,
        curriculum_s_unit_id: this.params?.curriculumSUnitId,
        study_type: 'similar',
        class_category_id: this.currentQuestion?.classCategoryId,
        class_mode_id: this.currentQuestion?.classModeId,
        study_material: 'custom',
      })
      Vue.prototype.$loading.complete()
      const resultDrillId = startRetry?.data?.resultDrillId
      if (resultDrillId) {
        window.open(`/student/v3/drill-similar-math/${this.currentQuestion.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${this.params?.curriculumSUnitId}&drillType=${DRILL_TYPE.DRILL_CUSTOM}`, '_blank');
      }
    } catch (error) {
      const status = (error as any).response?.data?.status
      if (status === 422 || status === 400) {
        alert('類題の生成に失敗しました。しばらくしてから再試行してください。')
        return;
      }
    }
  }

  private get questionUrl() {
    if (!this.currentQuestion.questionUrls.length) return ''
    return this.currentQuestion.questionUrls[0]
  }

  private get answerUrl() {
    return this.currentQuestion.answerUrl
  }

  private get getStarUrl() {
    return this.statusBookmark ? '/img/icons/star-mark-active-v2.png' : '/img/icons/star-mark-v2.png'
  }

  private async handleSaveMemo() {
    this.currentCanvasData[this.currentQuestion.questionCode] = {
      memo: {
        ...this.getDataStroke(),
      }
    }

    Vue.prototype.$loading.start()
    const strokes = this.currentCanvasData[this.currentQuestion.questionCode].memo.undoStack;
    const imageFile = await this.drawImageAndStrokes(this.currentQuestion.memoUrlTeacher, strokes, {
      widthCanvas: this.imageWidth,
      nameFile: Date.now().toString()
    }) as File
    if (this.isTeacher || !this.hiddenUi?.pen) {

      await this.saveMemoV2({
        page_num: this.currentQuestion.page.toString(),
        result_drill_id: this.params?.resultDrillId.toString() as string,
        question_code: this.currentQuestion.questionCode,
        memo_img: imageFile,
        memo_coordinates: "",
      })

      this.onChangeTabButton?.(this.currentQuestion.questionCode, { isRefetch: true })
      this?.onSaveMemoSuccess?.(this.currentQuestion.questionCode)
      Vue.prototype.$loading.complete()
      return
    }

    await this.saveMemo({
      page_num: this.currentQuestion.page.toString(),
      result_drill_id: this.params?.resultDrillId.toString() as string,
      question_code: this.currentQuestion.questionCode,
      memo_img: imageFile,
      memo_coordinates: ""
    })

    this.onChangeTabButton?.(this.currentQuestion.questionCode, { isRefetch: true })
    this?.onSaveMemoSuccess?.(this.currentQuestion.questionCode)
    Vue.prototype.$loading.complete()
  }

  private handleLoadImage(event: any) {
    const img = event.target
    const ratio = img.width / img.height;
    this.rationOfQuestion = ratio
  }

  public async handleGetStatusBookmark() {
    const result = await Vue.prototype.$http.httpWithToken.get(`/v3/history/question_bookmarks/bookmark_status`, { params: { question_code: this.currentQuestion.questionCode, curriculum_s_unit_id: this.params?.curriculumSUnitId, result_drill_id: this.params?.resultDrillId } })
    this.statusBookmark = result?.data?.isBookmard;
  }

  private async handleSimilarOrRetryHint(type: 'challenge_similar' | 'challenge_retry') {
    try {
      Vue.prototype.$loading.start()
      const result = await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_challenges`, {
        question_code: this.currentQuestion.questionCode,
        history_type: type,
        result_drill_id: this.params?.resultDrillId,
      })
      Vue.prototype.$loading.complete()
      if (result?.data?.status === 200) {
        alert('問題をチャレンジに保存しました。')
      }
    } catch (err) {
      const codeRes = (err as any)?.response?.data?.status
      if (codeRes === 400) {
        alert('問題は既にチャレンジに保存しました。')
      }
    }
  }

  private handleChangeLineWidth(type: 'thin' | 'medium' | 'advand') {
    const lineWidth = type === 'thin' ? LINE_WIDTH.THIN : type === 'medium' ? LINE_WIDTH.MEDIUM : LINE_WIDTH.ADVAND;
    this.changeLineWidth(lineWidth)
  }


  @Debounce(300)
  private handleResize() {
    const width = this.memoContentRef.clientWidth
    this.imagePreviousWidth = this.imageWidth
    this.imageWidth = width

    const instanceCanvas = this.getCurrentCanvas()
    const instanceCanvasStudent = this.getCurrentCanvas(this.canvasMemoStudent);

    instanceCanvas.canvas.width = width
    instanceCanvas.canvas.height = 1000
    instanceCanvasStudent.canvas.width = width
    instanceCanvasStudent.canvas.height = 1000


    this.handleLoadMemo(this.currentQuestion.memoUrl, this.currentQuestion.memoUrlTeacher)
    this.calculatorHeightMemo()
  }

  @Watch('currentQuestion', { deep: true })
  onChangeCurrentValue(newValue: QuestionsMemoMathType) {
    if (!this.tabButtonRef) return
    this.handleLoadMemo(newValue.memoUrl, newValue.memoUrlTeacher)
  }

  @Watch('questions', { deep: true })
  onChangeQuestion(newValue: QuestionsMemoMathType[]) {
    let page = 1;
    let indexPage = 0
    this.tabButtonData = newValue?.map((item) => {
      if (page !== item.page) {
        page = item.page;
        indexPage = 0
      }
      const data = {
        id: item.questionCode + '_' + item.page,
        text: item.page + '-' + (indexPage + 1),
      }
      indexPage += 1;
      return data
    })

  }

  private handleChangeHandStatus(oldStatusHand: boolean) {
    if (oldStatusHand) {
      this.handleChangePen(PEN_COLOR_KEY.RED)
      this.drillPenMathRef.onSetActiveColor(PEN_COLOR_KEY.RED)
    }
  }

  private created() {
    this.canvasName = 'instanceCanvasTeacher'
  }

  private handleActiveErase() {
    this.setEraser()
  }

  private handleChangeEraseStatus(oldStatusErase: boolean) {
    if (oldStatusErase) {
      this.deactivateCanvas()
      this.handleChangePen(PEN_COLOR_KEY.RED)
      this.drillPenMathRef.onSetActiveColor(PEN_COLOR_KEY.RED)
      return;
    }

    this.activateCanvas()
    this.setEraser();
  }
}

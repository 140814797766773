













import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { throttle } from 'lodash'
@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalHint extends Mixins(ModalBaseMethod) {
  @Prop()
  private clickQuestion!: () => void

  @Prop({ default: { isHidden: true, onClick: undefined } })
  private retryProps!: {
    isHidden?: boolean,
    onClick?: () => void
  }

  @Prop({ default: { onClick: undefined } })
  private similarProps!: {
    onClick?: () => void
    isHidden?: boolean
  }

  @Prop()
  question_code!: string;

  @Prop()
  result_drill_id!: string;

  @Prop()
  curriculum_s_unit_id!: string;

  @Prop()
  isHiddenBookmark!: string;

  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode || 'TJ'
  }

  private triggerHiddenTippy(e: Event) {
    const event = new Event('hidden-tippy')
    window.dispatchEvent(event)
  }


  private statusBookmark = false

  private get getStarUrl() {
    return this.statusBookmark ? '/img/icons/star-mark-active.png' : '/img/icons/star-mark.svg';
  }

  private async handleChangeStatusBookmark() {
    const currentStatus = this.statusBookmark
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_bookmarks`, { question_code: this.question_code, curriculum_s_unit_id: this.curriculum_s_unit_id, result_drill_id: this.result_drill_id })
    Vue.prototype.$loading.complete()
    this.statusBookmark = !this.statusBookmark
    if (!currentStatus) {
      alert('問題をブックマークを保存しました。')
    } else {
      alert('問題をブックマークから削除しました。')
    }

  }

  private handlScroll = throttle(this.triggerHiddenTippy, 500)


  public async handleGetStatusBookmark(question_code: string, curriculum_s_unit_id: string) {
    const result = await Vue.prototype.$http.httpWithToken.get(`/v3/history/question_bookmarks/bookmark_status`, { params: { question_code: question_code || this.question_code, curriculum_s_unit_id: curriculum_s_unit_id || this.curriculum_s_unit_id, result_drill_id: this.result_drill_id } })
    this.statusBookmark = result?.data?.isBookmard;
  }
}

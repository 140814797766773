






















































import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ConversionRoom from '@/components/modules/drillsv3/organisms/ConversionRoom.vue'
import { Message, ResponseQuestions } from '@/types/student'
import IconLoading from '@/components/atoms/v3/IconLoading.vue'
import PropertiesDesign from '@/mixins/v3/PropertiesDesign'
import CanvasMethod from '@/mixins/v3/canvas'
import MemoMath from '@/components/modules/drillsv3/organisms/MemoMath.vue'

const PER_PAGE = 10

@Component({
  components: {
    ModalBase,
    ButtonBase,
    ConversionRoom,
    IconLoading,
    MemoMath
  },
})
export default class ModalChatMath extends Mixins(ModalBaseMethod, PropertiesDesign, CanvasMethod) {
  @Ref() input!: HTMLTextAreaElement
  @Ref() conversionRef!: ConversionRoom

  @Prop()
  private curriculumSUnitId!: string | number
  @Prop({ default: '' })
  private userAnswerPath?: string
  @Prop()
  private questionCode!: string

  @Prop()
  isHideInputMessage!: boolean;

  @Prop()
  resultDrillId!: string;

  @Prop({
    default: {
      questionUrl: undefined,
      memoUrl: undefined
    }
  })
  params!: {
    questionUrl?: string;
    memoUrl?: string
  };

  private isLoadMore = false
  private isLoadData = false
  private error = false
  private isWaitReply = false
  private lastMessage = false
  private totalPage = 2
  private messages: Message[] = []
  private showMemo = false

  private calculatorHeightInput() {
    this.valueInput = this.input.value
    this.input.style.height = 'auto'
    this.input.style.height = this.input.scrollHeight + 'px'
  }

  private valueInput = ''

  public async showModal() {
    this.show()

    // this.messages = await this.sleep()
    this.messages = await this.getConversions()
    if (!this.messages.length) {
      this.lastMessage = true
    }
    this.isLoadData = true
  }

  private resetData() {
    this.messages = []
    this.isLoadData = false
    this.totalPage = 0
    this.lastMessage = false
    this.error = false
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      this.sendMessage()
      e.preventDefault()
    }
  }

  private calculatorCurrentPage() {
    const excess = this.messages.length % PER_PAGE
    let currentPage = 1
    if (excess === 0) {
      currentPage = this.messages.length / PER_PAGE
    } else {
      currentPage = Math.floor(this.messages.length / PER_PAGE)
    }
    return { excess, currentPage }
  }

  private async loadMore() {
    const { currentPage, excess } = this.calculatorCurrentPage()
    if (this.lastMessage || !this.totalPage) {
      return
    }
    this.isLoadMore = true
    const res = await this.getConversions(currentPage + 1)

    this.messages.unshift(...res.slice(0, res.length - excess))
    this.isLoadMore = false
    if (excess > 5) {
      this.loadMore()
    }
  }

  private async getConversions(page = 1) {
    try {
      const res = await Vue.prototype.$http.httpWithToken.get(`/v3/question_hints`, {
        params: {
          page: page,
          per_page: PER_PAGE,
          curriculum_s_unit_id: this.curriculumSUnitId,
          question_code: this.questionCode,
          result_drill_id: this.resultDrillId
        },
      })
      if (res.data) {
        this.totalPage = (res.data as ResponseQuestions).totalPage
        if (page == this.totalPage || !this.totalPage) {
          this.lastMessage = true
        }
        return (res.data as ResponseQuestions).histories.reverse()
      }
    } catch {
      this.error = true
      return []
    }
    return []
  }

  getFiveHistoriesLastest() {
    const length = this.messages.length
    const result = []
    for (let i = length - 1; i >= 0; i--) {
      if (!this.messages[i].error && this.messages[i].hintMessage) {
        result.push(this.messages[i])
      }
      if (result.length === 5) {
        break
      }
    }
    return result.reverse()
  }

  private async replyMessage(message: string, find_mistake?: boolean) {
    const fiveHistoriesLastest = this.getFiveHistoriesLastest()
    try {
      const res = await Vue.prototype.$http.httpWithToken.post(`/v3/question_hints`, {
        curriculum_s_unit_id: this.curriculumSUnitId,
        question_code: this.questionCode,
        user_message: message,
        histories: fiveHistoriesLastest,
        user_answer_path: this.userAnswerPath,
        result_drill_id: this.resultDrillId,
        find_mistake: !!find_mistake
      })
      if (res.data) {
        return res.data.histories[0]
      }
    } catch {
      return { hintMessage: 'ネットワークにエラーが発生しました。', userMessage: message, createdAt: new Date().toDateString(), error: true }
    }
    return { hintMessage: 'ネットワークにエラーが発生しました。', userMessage: message, createdAt: new Date().toDateString(), error: true }
  }

  private mountMessage(messageInput?: string) {
    const message = messageInput || this.input.value

    this.messages.push({ hintMessage: null, userMessage: message, createdAt: new Date().toDateString() })
    this.input.value = ''
    this.calculatorHeightInput()
    this.scrollBottom()
  }

  private async mountReply(message: string, find_mistake?: boolean) {
    this.isWaitReply = true
    const reply = (await this.replyMessage(message, find_mistake)) as Message
    if (reply) {
      this.messages.splice(this.messages.length - 1, 1, reply)
    }
    this.isWaitReply = false
  }

  private async sendMessage(messageInput?: string, find_mistake?: boolean) {
    if (this.isWaitReply || !(messageInput || this.input.value)) {
      return
    }
    const message = messageInput || this.input.value
    this.mountMessage(message)
    await this.mountReply(message, find_mistake)
  }

  private scrollBottom() {
    this.conversionRef.scrollBottom()
  }

  private get varibleBot() {
    return {
      '--url-img-bot': `url("${this.characters.default}")`,
      '--url-img-bot-error': `url("${this.characters.error}")`,
    }
  }

  private get memoClass() {
    return this.showMemo ? 'modalChatMath__body-memoActive' : ''
  }

  private drawImageFromMemoStudentUrl(memoUrl: string) {
    if (!memoUrl) {
      this.clearCanvas()
      return;
    }

    const image = new Image()
    image.src = memoUrl
    const _this = this

    // try {
    //   Vue.prototype.$loading.start()
    //   image.onload = () => {
    //     const ratio = image.width / image.height
    //     _this.getCurrentCanvas().context.drawImage(image, 0, 0, this.imageWidth, this.imageWidth / ratio)
    //   }
    // } catch (err) {
    //   console.log(err)
    // } finally {
    //   Vue.prototype.$loading.complete()
    // }
  }
}

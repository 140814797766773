








import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'
@Component({
  components: {
    ModalBase,
  },
})
export default class ModalMyAnswer extends Mixins(ModalBaseMethod) {
  @Prop()
  urlAnswer!: string


}

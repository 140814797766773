


















import { Component, Vue, Prop } from 'vue-property-decorator'
import ImageVertical from '@/components/modules/drillsv3/molecules/ImageVertical.vue'
import ImageHorizontal from '@/components/modules/drillsv3/molecules/ImageHorizontal.vue'
import ImageTextExplain from '@/components/modules/drillsv3/molecules/ImageTextExplain.vue'

@Component({
  components: {
    ImageVertical,
    ImageHorizontal,
    ImageTextExplain,
  },
})
export default class ImageExpansion extends Vue {
  private show = false

  private pageIndex: number | null = null
  private questionIndex: number | null = null
  private isTextImageExplain = true

  @Prop()
  subjectCode!: string

  private get isVertical() {
    // 国語の場合はtrue
    return this.subjectCode === 'ko'
  }

  /**
   * 表示(外部から呼び出す)
   */
  public showImage(pageIndex: number, isTextImageExplain?: boolean, questionIndex?: number): void {
    this.pageIndex = pageIndex
    this.questionIndex = questionIndex ?? null
    this.show = true
    this.isTextImageExplain = isTextImageExplain || false
  }

  /**
   * 表示終了
   */
  private hideImage(): void {
    this.pageIndex = null
    this.show = false
  }

  /**
   * 現在のページ番号
   *
   * @param pageIndex imageUrls配列のindex番号
   * @return ページ番号
   */
  private pageNumber(pageIndex: number): number {
    // クエリ指定のページかindex + 1の値を返す
    //  クエリ指定あり → 単独ページ表示なのでその値
    //  クエリ指定なし → 複数ページ表示なのでインデックス+1
    return Number(this.$route.query.page) || pageIndex + 1
  }
}















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CorrectGuide extends Vue {

  private mounted() {
    Vue.prototype.$logger.info('-- CorrectGuide V3 mounted')
  }

  private get correctCount(): number {
    return this.$store.getters['drillsV3/correctCount']
  }

  private get questionCount(): number {
    return this.$store.getters['drillsV3/questionCount']
  }
}


























import { Component, Prop, Vue } from 'vue-property-decorator'
import RadioImaged from '@/components/atoms/RadioImaged.vue'

@Component({
  components: {
    RadioImaged,
  },
})
export default class RadioOkNg extends Vue {
  @Prop()
  name?: string
  @Prop({ default: { ok: false, notGood: false } })
  checked?: { ok: boolean; notGood: boolean }
}

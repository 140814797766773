






import PenCanvasMath from '@/components/modules/drills/atoms/PenCanvasMath.vue'
import TabsButtonMath from '@/components/modules/drillsv3/molecules/TabsButtonMath.vue'
import ModalChatMath from '@/components/modules/drillsv3/organisms/ModalChatMath.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/v3/ModalBase.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'
import DrillPenAi from '@/components/organisms/study/DrillPenAi.vue'
import DrillPenMath from '@/components/organisms/study/DrillPenMath.vue'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import CanvasMethod from '@/mixins/v3/canvas'
import { Debounce } from 'vue-debounce-decorator'
import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ModalBase,
    DrillPen,
    PenCanvasMath,
    DrillPenAi,
    TabsButtonMath,
    DrillPenMath,
    ModalChatMath
  },
})
export default class MemoMath extends Mixins(ModalBaseMethod, CanvasMethod, DrillWebApi) {
  @Ref() memoContentRef!: HTMLElement

  @Prop({
    default: {
      questionUrl: undefined,
      memoUrl: undefined
    }
  })
  params!: {
    questionUrl?: string;
    memoUrl?: string
  };

  private imageWidth = 0

  private created() {
    this.canvasName = 'memoMathStudent'
  }

  private handleLoadMemo(base64Url?: string) {
    this.clearCanvas()
    this.drawImageFromMemoStudentUrl(base64Url)
  }

  private drawImageFromMemoStudentUrl(memoUrl?: string) {
    if (!memoUrl) return;

    const image = new Image()
    image.src = memoUrl
    const _this = this

    try {
      Vue.prototype.$loading.start()
      image.onload = () => {
        const ratio = image.width / image.height
        _this.getCurrentCanvas().context.drawImage(image, 0, 0, this.imageWidth, this.imageWidth / ratio)
      }
    } catch (err) {
      console.log(err)
    } finally {
      Vue.prototype.$loading.complete()
    }
  }

  private mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  private unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }

  @Debounce(300)
  private handleResize() {
    const width = this.memoContentRef.clientWidth
    this.imageWidth = width

    const instanceCanvas = this.getCurrentCanvas()
    instanceCanvas.canvas.width = width
    instanceCanvas.canvas.height = 1000


    this.handleLoadMemo(this.params?.memoUrl)
  }
}

import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=010f2a96&scoped=true&lang=pug&"
import script from "./ErrorMessage.vue?vue&type=script&lang=ts&"
export * from "./ErrorMessage.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorMessage.vue?vue&type=style&index=0&id=010f2a96&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010f2a96",
  null
  
)

export default component.exports




















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class DrillPenActiveIconMath extends Vue {
  @Prop()
  props!: Partial<any>

  @Prop()
  onClick!: () => void

  private handleClick() {
    this.onClick?.()
  }
}

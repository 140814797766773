






import { Component, Prop, Vue } from 'vue-property-decorator'
import { Message } from '@/types/student'
import ChatLine from '@/components/modules/drillsv3/atoms/ChatLine.vue'
import { isString } from 'lodash'

@Component({
  components: {
    ChatLine,
  },
})
export default class ModalChat extends Vue {
  @Prop()
  private message!: Message

  @Prop()
  private isWaitReply?: boolean

  private get showMessageBot() {
    return isString(this.message.hintMessage)
  }
}

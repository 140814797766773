









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ModalChat extends Vue {
  @Prop()
  private message!: string
  @Prop()
  private isMe?: boolean
  @Prop()
  private error?: boolean

  @Prop()
  private isWaitReply?: boolean

}















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import MessageComponent from '@/components/modules/drillsv3/molecules/Message.vue'
import { Message } from '@/types/student'
import ChatLine from '@/components/modules/drillsv3/atoms/ChatLine.vue'
import IconLoading from '@/components/atoms/v3/IconLoading.vue'

const POSITION_SCROLL_BEGIN_CALL_API = 500
const POSITION_SCROLL_BEGIN_SHOW_BUTTON = 500

@Component({
  components: {
    ButtonBase,
    MessageComponent,
    ChatLine,
    IconLoading,
  },
})
export default class ModalChat extends Vue {
  @Ref() conversion!: HTMLDivElement

  @Prop()
  private messages!: Message[]

  @Prop()
  private isLoadMore?: boolean

  @Prop()
  private lastMessage?: boolean

  @Prop()
  private isWaitReply?: boolean

  private showBtnGoToBottom = false

  private get showBotAsk() {
    return (!this.messages.length || this.lastMessage) && !this.isLoadMore
  }

  private handleScroll(e: Event) {
    const target = e.target as HTMLDivElement
    // prevent scroll top
    if (target.scrollTop === 0) {
      target.scrollTop = 1
    }

    // condition scroll Bottom
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      target.classList.add('pinBottom')
    } else {
      target.classList.remove('pinBottom')
    }
    // condition display button go to bottom
    if (target.scrollTop < target.scrollHeight - target.offsetHeight - POSITION_SCROLL_BEGIN_SHOW_BUTTON) {
      this.showBtnGoToBottom = true
    } else {
      this.showBtnGoToBottom = false
    }

    //condition call more messages
    if (target.scrollTop < POSITION_SCROLL_BEGIN_CALL_API && !this.isLoadMore) {
      this.$emit('scroll-top')
    }
  }

  public scrollBottom() {
    setTimeout(() => {
      this.conversion.scrollTop = this.conversion.scrollHeight
    }, 50)
  }

  private mounted() {
    this.scrollBottom()
  }
}









import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import ResultGuide from '@/components/modules/drillsv3/atoms/ResultGuide.vue'
import PredictedScoreBar from '@/components/modules/drillsv3/organisms/PredictedScoreBar.vue'
import Correct from '@/components/modules/drillsv3/organisms/Correct.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillHistoryApi from '@/mixins/drillsv3/HistoryApi'
import ErrorMessage from '@/components/atoms/v3/ErrorMessage.vue'

@Component({
  components: {
    Title,
    ResultGuide,
    PredictedScoreBar,
    Correct,
    ErrorMessage,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, DrillHistoryApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private error = false

  private drillModules = DrillsConfig.drillModules

  // 表示するモジュールの切り替え
  private get activeModules() {
    return this.drillModules.RESULT_ALL_PREDICTED_SCORE
  }

  private version = this.$route.params.type

  protected async mounted(): Promise<any> {
    try {
      Vue.prototype.$loading.start()

      Vue.prototype.$logger.info('-- DrillResult V3 mounted')

      // 読み込み完了フラグを未完了に変更
      this.$store.commit('drillsV3/setProcessed', false)

      // ページタイプ設定
      this.$store.commit('drillsV3/setPageType', PAGE_TYPE.RESULT_ALL)
      const version = ['v2', 'v3'].includes(this.version) ? this.version : 'v3'

      const resultDrill = await this.getResultDrill(this.resultDrillId, version)

      // 授業モードと授業種別を保持
      this.$store.commit('drillsV3/setClassModeCode', resultDrill.classModeCode)
      this.$store.commit('drillsV3/setClassCategoryCode', resultDrill.classCategoryCode)

      // 表示用の情報を取得し、ストアに保持
      const sProblemIds = resultDrill.resultDrillPages.map((resultDrillPage) => {
        return resultDrillPage.problems.map((problem) => {
          return problem.sProblemId
        })
      })
      const problems = await this.getProblems(sProblemIds, version)
      await this.$store.dispatch('drillsV3/setProblems', problems)
      // 正誤情報をストアに保持
      const resultProblems = resultDrill.resultDrillPages.map((resultDrillPage) => {
        return resultDrillPage.problems
      })
      Vue.prototype.$loading.complete()

      await this.$store.dispatch('drillsV3/setProblemCorrects', resultProblems)
      // 動画視聴状況を登録
      await this.$store.dispatch('drillsV3/setMoviePlayed', resultProblems)

      // その他学習結果情報をストアに保持
      await this.$store.dispatch('drillsV3/setResultDrill', resultDrill)

      // 読み込み完了フラグを設定
      this.$store.commit('drillsV3/setProcessed', true)
      this.loadComplete = true

      Vue.prototype.$loading.complete()
    } catch {
      this.error = true
    }
  }
}

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { StrokeType, DataCanvasDrillMethod } from '@/types/canvas'
import { PEN_COLOR, LINE_WIDTH } from '@/constants'

const ERASER_LINE_WIDTH = 15
@Component
export default class PenCanvasMath extends Vue {
  @Prop()
  name!: string

  private currentStroke: StrokeType[] = []
  private strokes: StrokeType[][] = []
  private isDrawable = false
  private lineWidth = LINE_WIDTH.MEDIUM
  private canvas: HTMLCanvasElement | null = null
  private context: CanvasRenderingContext2D | null = null
  private isDrag = false
  private penColor = PEN_COLOR.BLACK
  private alpha = 1
  private compositeOperation = 'source-over'
  private redoStack: any = []
  private deletedStack: any = []
  private undoType: ('line' | 'delete' | 'erase')[] = []
  private redoType: ('line' | 'delete' | 'erase')[] = []
  private realStrokes: any = []
  private get refs(): any {
    return this.$refs
  }
  private eraseStatus = false

  private mounted() {
    if (!Vue.prototype.$penCanvases) Vue.prototype.$penCanvases = {}
    Vue.prototype.$penCanvases[this.name] = this
    this.canvas = this.refs['penCanvas']
    if (!this.canvas) return
    this.context = this.canvas.getContext('2d')
  }

  private activateCanvas() {
    this.isDrawable = true
  }

  private deactivateCanvas() {
    this.isDrawable = false
  }

  /**
   * canvasのサイズ変更
   */
  private changeSize({ width = 0, height = 0 }: { width: number; height: number }): void {
    if (!this.canvas) return
    this.canvas.width = width
    this.canvas.height = height
    this.setCanvasContext()
  }

  /**
   * canvasのパラメータ設定
   */
  private setCanvasContext(): void {
    if (!this.context) return
    this.context.globalCompositeOperation = this.compositeOperation as any
    this.context.lineCap = 'round'
    this.context.lineJoin = 'round'
    this.context.lineWidth = this.lineWidth
    this.context.strokeStyle = this.penColor
    this.context.globalAlpha = this.alpha
  }

  /**
   * ペンの種類を設定
   */
  private changePenType({ colorCode, alpha = 1 }: { colorCode: string; alpha: number }): void {
    this.penColor = colorCode
    this.alpha = alpha
    this.compositeOperation = 'source-over'
    this.setCanvasContext()
  }

  /**
   * canvasに消しゴム設定
   */
  private setEraser(): void {
    this.lineWidth = ERASER_LINE_WIDTH
    this.compositeOperation = 'destination-out'
    this.alpha = 1
    this.penColor = 'white'
    this.setCanvasContext()
  }
  /**
   * 描画
   */
  private draw(e: any): void {
    e.preventDefault() // スクロール抑制
    if (!this.context || !this.isDrag) return
    const { x, y } = this.caliculatePosition(e)

    this.currentStroke.push({ x, y, color: this.penColor, lineWidth: this.lineWidth })
    this.context.lineTo(x, y)
    this.context.stroke()
  }

  public getRealStrokes() {
    const deleteStrokes = this.strokes.reduce((result: StrokeType[], item) => {
      const data = item.reduce((_result: StrokeType[], _item) => _item.lineWidth === ERASER_LINE_WIDTH ? [..._result, _item] : _result, [])
      return [...result, ...data]
    }, [])
    let strokes = this.strokes
    for (let i = 0; i < deleteStrokes.length; ++i) {
      strokes = this.handleGetStrokesWithoutEraseV2(strokes, deleteStrokes[i].x, deleteStrokes[i].y)
    }
    return strokes
  }

  private handleGetStrokesWithoutErase(strokes: StrokeType[][], x: number, y: number) {
    return strokes.reduce((result: StrokeType[][], item) => {
      const data = item.reduce((_result: StrokeType[], _item) => {
        const distanceCoordinate = Math.sqrt(Math.pow(x - _item.x, 2) + Math.pow(y - _item.y, 2))
        if (distanceCoordinate <= ERASER_LINE_WIDTH / 2 || _item.lineWidth === ERASER_LINE_WIDTH) {
          return _result;
        }
        return [..._result, _item]
      }, [])
      if (data.length) return [...result, data]
      return result
    }, [])
  }

  private handleGetStrokesWithoutEraseV2(strokes: StrokeType[][], x: number, y: number) {
    return strokes.reduce((result: StrokeType[][], item) => {
      let data = []
      const newStroke = []
      for (let i = 0; i < item.length; ++i) {
        if (item[i].lineWidth === ERASER_LINE_WIDTH) {
          continue;
        }

        const distanceCoordinate = Math.sqrt(Math.pow(x - item[i].x, 2) + Math.pow(y - item[i].y, 2))
        if (distanceCoordinate <= ERASER_LINE_WIDTH / 2) {
          if (data.length) {
            newStroke.push(data)
          }
          data = []
        } else {
          data.push(item[i])
        }
      }
      data.length && newStroke.push(data)
      return [...result, ...newStroke]
    }, [])
  }

  private drawFromStroke(strokes: StrokeType[][]): void {
    if (!this.context || !this.isDrag) return
    const ctx = this.context
    const _this = this
    strokes.forEach(function (stroke: StrokeType[]) {
      ctx.beginPath()
      ctx.moveTo(stroke[0].x, stroke[0].y)
      stroke.forEach(function (point: StrokeType) {
        ctx.lineWidth = point?.lineWidth || _this.lineWidth
        ctx.strokeStyle = point?.color || _this.penColor
        ctx.lineTo(point.x, point.y)
      })
      ctx.stroke()
    })
  }

  /**
   * 描画開始
   */
  private dragStart(e: any): void {
    e.preventDefault() // スクロール抑制
    if (!this.context) return
    this.context.strokeStyle = this.penColor
    this.context.lineWidth = this.lineWidth
    const { x, y } = this.caliculatePosition(e)
    this.currentStroke.push({ x, y, color: this.penColor, lineWidth: this.lineWidth })
    this.context.beginPath()
    this.context.lineTo(x, y)
    this.context.stroke()
    this.isDrag = true
    this.$emit('drag-start')
  }

  /**
   * 描画終了
   */
  private dragEnd(): void {
    if (!this.context) return

    switch (this.lineWidth) {
      case LINE_WIDTH.MEDIUM: case LINE_WIDTH.THIN: case LINE_WIDTH.ADVAND: case ERASER_LINE_WIDTH: {
        if (this.currentStroke.length) {
          this.strokes = this.strokes.concat([this.currentStroke])
          this.$emit('drag-end', this.currentStroke)
          this.undoType.push('line')
          this.currentStroke = []
          this.redoStack = []
        }
        break
      }
    }
    this.context.closePath()
    this.isDrag = false
  }

  /**
   * 描画座標を取得
   */
  private caliculatePosition(e: any): any {
    // スマホの場合とで描画座標の取得方法が異なる
    if (!this.canvas) return
    let [x, y] = [e.offsetX, e.offsetY]
    // スマホ対応
    if (e.touches !== undefined) {
      if (!this.canvas) return
      const client = this.canvas.getBoundingClientRect()
      x = e.touches[0].pageX - client.left
      y = e.touches[0].pageY - (client.top + window.pageYOffset)
    }

    return { x, y }
  }

  private getCanvasAsImageDataType() {
    const imageData = this.context?.getImageData(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
    return imageData?.data
  }

  private drawCanvasFromImageData(imageData: Uint8ClampedArray) {
    const restoredImageData = new ImageData(imageData, this.canvas?.width || 0, this.canvas?.height || 0)

    this.context?.putImageData(restoredImageData, 0, 0)
  }

  private exportCanvasToFileImage(nameImage?: string) {
    const imageDataUrl = this.canvas?.toDataURL('image/png')

    // Chuyển đổi thành ArrayBuffer
    const byteString = atob(imageDataUrl?.split(',')?.[1] || '')
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }

    // Tạo Blob từ ArrayBuffer
    const blob = new Blob([uint8Array], { type: 'image/png' })

    // Tạo File từ Blob
    return new File([blob], `${nameImage}.png`, { type: 'image/png' })
  }

  private canvasToFile(nameImage?: string) {
    return new Promise((resolve, reject) => {
      this.canvas?.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create image blob'))
        } else {
          const file = new File([blob], nameImage || 'image.png', { type: 'image/png' })
          resolve(file)
        }
      }, 'image/png')
    })
  }

  private clearCanvas() {
    this.context?.clearRect(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
    this.clearStrokes()
    this.redoStack = []
    this.redoType = []
  }

  private checkCanvasIsEmpty() {
    const imageData = this.context?.getImageData(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
    const data = imageData?.data

    let isEmpty = true

    // Check if any non-transparent pixel is present
    for (let i = 3; i < (data?.length || 0); i += 4) {
      if (data?.[i] !== 0) {
        isEmpty = false
        break
      }
    }

    return isEmpty
    // return !this.strokes.length
  }

  private canvasToBase64() {
    const dataURL = this.canvas?.toDataURL()
    return dataURL
  }

  private drawImageFromBase64(
    base64String: string,
    options: {
      width: number
      height: number
    }
  ) {
    const img = new Image()
    const canvas = this
    img.src = base64String
    img.onload = function () {
      canvas.clearCanvas()
      canvas?.context?.drawImage(img, options.width, options.height)
    }
  }

  private async drawImageFromBase64WithoutClear(
    base64String: string,
    options: {
      width: number
      height: number
    }
  ) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const canvas = this;

      img.src = base64String;
      img.onload = function () {
        canvas?.context?.drawImage(img, 0, 0, options.width, options.height);
        resolve('success');
      };
      img.onerror = function () {
        reject(new Error('Failed to load image'));
      };
    });
  }

  public drawImageFromElement(
    imgEl: any,
    options: {
      width: number
      height: number
    }
  ) {
    this?.context?.drawImage(imgEl, options.width, options.height)
  }

  public getStroke() {
    return this.strokes
  }

  public clearStrokes() {
    this.strokes = []
    this.currentStroke = []
  }

  public redraw(
    strokes?: StrokeType[][],
  ) {
    const _this = this
    const realData = strokes || this.strokes

    const ctx = _this.context
    if (ctx) {
      ctx.clearRect(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)

      const isEraseMode = this.getCompositeOperation() === 'destination-out'
      if (isEraseMode) {
        ctx.globalCompositeOperation = 'source-over'
      }

      // console.log({ realData }, 'realData');

      realData.forEach(function (stroke: StrokeType[]) {
        ctx.beginPath()
        ctx.moveTo(stroke[0].x, stroke[0].y)
        stroke.forEach(function (point: StrokeType) {
          const isErase = point.lineWidth === ERASER_LINE_WIDTH
          if (isErase) {
            ctx.globalCompositeOperation = 'destination-out';
          }
          ctx.lineWidth = point?.lineWidth || _this.lineWidth
          ctx.strokeStyle = point?.color || _this.penColor
          ctx.lineTo(point.x, point.y)
          if (isErase) {
            ctx.globalCompositeOperation = 'source-over';
          }
        })
        ctx.stroke()
      })

      if (isEraseMode) {
        ctx.globalCompositeOperation = 'destination-out'
      }
    }

    if (strokes) {
      this.strokes = strokes
    }
  }

  public getWidthHeight() {
    return {
      width: this.canvas?.width,
      height: this.canvas?.height,
    }
  }

  public async undo() {
    if (!this.undoType.length) return

    const isEraseMode = this.compositeOperation === 'destination-out'

    if (isEraseMode && this.context) {
      this.context.globalCompositeOperation = 'source-over'
    }

    if (this.undoType[this.undoType.length - 1] === 'line') {
      const stroke = this.strokes.pop()
      if (stroke) {
        this.redoStack.push(stroke)
        this.redoType.push(this.undoType.pop() || 'line')
        this.redraw()
      }
    } else {
      this.strokes = this.deletedStack.pop()[0]
      this.redoStack.push([])
      this.redoType.push(this.undoType.pop() || 'delete')
      this.redraw()
    }


    if (isEraseMode && this.context) {
      this.context.globalCompositeOperation = 'destination-out'
    }
  }

  public async redo() {
    if (!this.redoType.length) return

    const isEraseMode = this.compositeOperation === 'destination-out'

    if (isEraseMode && this.context) {
      this.context.globalCompositeOperation = 'source-over'
    }

    if (this.redoType[this.redoType.length - 1] === 'line') {
      const stroke = this.redoStack.pop()
      if (stroke) {
        this.strokes.push(stroke)
        this.undoType.push(this.redoType.pop() || 'line')
        this.redraw()
      }
    } else {
      this.deletedStack.push([this.strokes])
      this.strokes = this.redoStack.pop()
      this.undoType.push(this.redoType.pop() || 'delete')
      this.redraw()
    }

    if (isEraseMode && this.context) {
      this.context.globalCompositeOperation = 'destination-out'
    }
  }

  public changePenColor(color: string) {
    this.penColor = color
  }

  public getDataStroke() {
    return {
      redoType: this.redoType,
      undoType: this.undoType,
      redoStack: this.redoStack,
      deletedStack: this.deletedStack,
      undoStack: this.strokes,
      realStrokes: this.getRealStrokes()
    }
  }

  public setDataStroke(params: DataCanvasDrillMethod) {
    this.redoType = params.redoType
    this.undoType = params.undoType
    this.redoStack = params.redoStack
    this.deletedStack = params.deletedStack
    this.strokes = params.undoStack
  }

  public deleteCanvas() {
    if (!this.strokes.length) return
    this.deletedStack.push([this.strokes])
    this.undoType.push('delete')
    this.clearStrokes()
    this.redraw()

  }

  public changeLineWidth(lineWidth: number) {
    if (!this.context) return
    this.context.lineWidth = lineWidth;
    this.lineWidth = lineWidth;
  }

  public getCompositeOperation() {
    return this.compositeOperation
  }
}
